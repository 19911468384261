@import '../../index.css';

.global-container {
    height: 100%;
    overflow: auto;
    color: var(--text-color-1);

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #555;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }
}

.my-profile {
    backdrop-filter: blur(60px);
    color: var(--text-color-1);
    border-color: var(--border-color);
}

.timeline-main-wrapper {
    padding: unset !important;

    &>* {
        padding: unset !important;
    }
}

.my-card {
    background-color: unset !important;
    backdrop-filter: blur(60px) !important;
    border: solid var(--border-color) !important;
    color: var(--text-color-1) !important;

    & span {
        background-color: unset;
    }
}

div[data-testid=tree-leaf] {
    &::after {
        background-color: var(--border-color);
    }

    &::before {
        background-color: var(--border-color);
    }

    & button>div {
        background-color: var(--border-color) !important;
        border: 3px solid var(--border-color) !important;

        &.active {
            background-color: white !important;
        }
    }
}

.timeline-item-title {
    background-color: unset;
    color: var(--text-color-1) !important;

    &.active {
        transition: all 0.7s;
        background-color: var(--border-color) !important;
    }
}

.my-card-subtitle {
    color: var(--text-color-1) !important;
}


h1.my-card-title {
    color: var(--text-color-2);
}

.my-card-text {
    &>* {
        color: var(--text-color-1) !important;
    }
}

/* KNOWLEDGE SECTION */

.global-container:has(.knowledge-container[data-visible="true"]) {
    & .particles-container {
        & #tsparticles canvas {
            /* background: var(--background-color-2);
            transition: var(--background-transition); */
        }
    }
}

.global-container:has(.profile-container[data-visible="true"]) {
    & .particles-container {
        & #tsparticles canvas {
            /* background: var(--background-color-1);
            transition: var(--background-transition); */
        }
    }
}

.content-container{
    padding-bottom: 30vh;
    
    h4 {
        margin-bottom: 5vh;
    }
}