@import '../../index.css';

.particles-container {
    position: fixed;
    display: flex;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    justify-content: center;
    background: var(--background-color-1);

    & #tsparticles {
        width: 100%;

        & canvas {
            background-color: var(--background-color-1);
            transition: all 0s ease-in-out;
        }
    }
}