@import '../../index.css';

.profile-container {
    margin-bottom: 1px;
    height: 100vh;
    width: 100vw;

    & img {
        transition: rotate 0.2s ease-in-out;
        box-shadow: 0px 0px 10px 20px var(--background-color-1);

        &:hover {
            rotate: 360deg;
        }
    }
}

.curly-braces{
    left: -3rem;
    /* font-size: 12rem; */
    z-index: 0;
    color: var(--text-color-2);
    opacity: 0.2;
}

@keyframes elastic-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(720deg);
    }
}

h1 {
    background: var(--gradiant-color-1);
    -webkit-background1-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

a{
    border: solid 2px var(--border-color);
    backdrop-filter: blur(2px);
}

.idea-effect{
    color: var(--yellow-color);
    font-weight: 400;
    /* text-shadow: 0 0 18px var(--yellow-color); */
    animation: flickerLG 2s linear reverse infinite;
}

@keyframes bulb-blink {
    0% {
        text-shadow: 0 0 18px var(--yellow-color);
    }

    50% {
        text-shadow: 0 0 4px var(--yellow-color);
    }

    100% {
        text-shadow: 0 0 18px var(--yellow-color);
    }
}

@keyframes flickerLG {
    0% {
        text-shadow: 0 0 7px var(--yellow-color);
    }

    5% {
        text-shadow: 0 0 9px var(--yellow-color);
    }

    10% {
        text-shadow: 0 0 11px var(--yellow-color);
    }

    15% {
        text-shadow: 0 0 15px var(--yellow-color);
    }

    25% {
        text-shadow: 0 0 9px var(--yellow-color);
    }

    30% {
        text-shadow: 0 0 18px var(--yellow-color);
    }

    35% {
        text-shadow: 0 0 5px var(--yellow-color);
    }

    40% {
        text-shadow: 0 0 11px var(--yellow-color);
    }

    45% {
        text-shadow: 0 0 9px var(--yellow-color);
    }

    60% {
        text-shadow: 0 0 19px var(--yellow-color);
    }

    70% {
        text-shadow: 0 0 15px var(--yellow-color);
    }

    80% {
        text-shadow: 0 0 19px var(--yellow-color);
    }

    90% {
        text-shadow: 0 0 9px var(--yellow-color);
    }

    100% {
        text-shadow: 0 0 19px var(--yellow-color);
    }
}