@import '../../index.css';

.theme-container{
    background: transparent;
    border: solid var(--border-color) 2px;
    backdrop-filter: blur(3px);
    cursor: pointer;

    & svg {
        font-size: 2rem;
        color: var(--text-color-1);
    }
}