@tailwind base;
@tailwind components;
@tailwind utilities;

@import './../node_modules/@radix-ui/colors/yellow.css';
@import './../node_modules/@radix-ui/colors/plum-dark.css';
@import './../node_modules/@radix-ui/colors/plum.css';
@import './../node_modules/@radix-ui/colors/purple-dark.css';
@import './../node_modules/@radix-ui/colors/purple.css';
@import './../node_modules/@radix-ui/colors/white-alpha.css';
@import './../node_modules/@radix-ui/colors/black-alpha.css';

@font-face {
  font-family: 'MaisonNeue';
  src: url("./assets/fonts/MaisonNeue/MaisonNeueLight.otf") format("opentype");
  font-weight: 100 300;
  font-style: normal;
}

@font-face {
  font-family: 'MaisonNeue';
  src: url("./assets/fonts/MaisonNeue/MaisonNeue.otf") format("opentype");
  font-weight: 400 600;
  font-style: normal;
}

@font-face {
  font-family: 'MaisonNeue';
  src: url("./assets/fonts/MaisonNeue/MaisonNeueBold.otf") format("opentype");
  font-weight: 700 1000;
  font-style: normal;
}

:root {
  --background-transition: all 1.5s ease;
  font-size: 0.8em;
}

#root.dark {
  --background-color-1: var(--plum-1);
  --background-color-2: var(--plum-4);
  --text-color-1: var(--plum-12);
  --text-color-2: var(--plum-11);
  --text-color-3: var(--white-a7);
  --border-color: var(--plum-6);

  --gradiant-color-1: linear-gradient(92.72deg, var(--purple-12) 0%, var(--purple-9) 100%);
  --yellow-color: var(--yellow-9)
}

#root.light {
  --background-color-1: var(--plum-3);
  --background-color-2: var(--plum-5);
  --text-color-1: var(--plum-12);
  --text-color-2: var(--plum-11);
  --text-color-3: var(--black-a7);
  --border-color: var(--plum-6);

  --gradiant-color-1: linear-gradient(92.72deg, var(--purple-12) 0%, var(--purple-9) 100%);
  --yellow-color: var(--yellow-8)
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'MaisonNeue';
  color: var(--text-color-1);
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

#root {
  overflow: hidden;
  height: 100vh;
  font-size: 0.8em;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

h4 {
  &[data-visible=true] {
    transition-property: all;
    transition-duration: 0.4s;
    transition-timing-function: ease-in;
    transform: translateY(0px);
  }

  &[data-visible=false] {
    transition-property: all;
    transition-delay: 0s !important;
    transition-duration: 0s !important;
    transform: translateY(20px);
    opacity: 0;
  }
}

.history-card, .projet-card {
  &:hover {
    transition: scale 0.2s linear !important;
    cursor: default;
    scale: 1.1;
    z-index: 100;

    & .history-background-card {
      filter: blur(0px);
      backdrop-filter: blur(8px);
      opacity: 1;
    }
  }
}

.fa-typescript {
  content: url('./assets/svg/typescript.svg');
  height: 1em;
}

.fa-tailwind {
  content: url('./assets/svg/tailwind.svg');
  height: 1em;
}