@import '../../index.css';

.history-card {
    min-height: 240px;

    & h4 {
        color: var(--text-color-1);
        text-shadow: 0 0 8px var(--text-color-1);
    }

    & p {
        text-align: justify;
        color: var(--text-color-3);
    }

    &[data-visible=true] {
        transition: transform 0.4s ease-in-out,
                    opacity 0.4s ease-in-out;
        transform: translateY(0px);
    }

    &[data-visible=false] {
        transition: translateY 0s none 0s !important;
        transform: translateY(20px);
        opacity: 0;
    }

    .history-background-card {
        border: solid var(--border-color) 2px;
        border-radius: 1rem;
        backdrop-filter: blur(2px);
        transition: all 0.2s ease-in-out;
    }
}

.badge {
    color: var(--background-color-1);
    background-color: var(--text-color-1);
}