@import '../../index.css';

.bar {
    height: 1rem;
    /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */
    /* background-size: 400% 400%; */
    /* animation: gradient 3s ease infinite; */
    animation: gradient 2s infinite;
    background: repeating-linear-gradient(to right, red 0%, blue 50%, red 100%);
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    background-size: 200% auto;
}

.animated-gradient {
    width: 100%;
    background: repeating-linear-gradient(to right, red 0%, blue 50%, red 100%);
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

@keyframes gradient {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -200% 0;
    }
}