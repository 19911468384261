@import '../../index.css';

.projet-card {
    flex: 1 1 25rem;
    flex-grow: 1;
    transition: rotate 0.1s ease-in-out;
    backdrop-filter: blur(3px);
    border: solid var(--border-color) 2px;
    border-radius: 1rem;
    min-height: 300px;

    & h3 {
        text-shadow: 0 0 8px var(--text-color-1);
        color: var(--text-color-1);
    }

    & p {
        color: var(--text-color-3);
    }

    &[data-visible=true] {
        transition: transform 0.4s ease-in-out var(--transition-delay-translate),
            opacity 0.4s ease-in-out var(--transition-delay-translate),
            rotate 0.1s ease-in-out;
        transform: translateY(0px);
    }

    &[data-visible=false] {
        transition: translateY 0s none 0s !important;
        transform: translateY(20px);
        opacity: 0;
    }

    &:nth-child(1) {
        --transition-delay-translate: 0.1s;
    }

    &:nth-child(2) {
        --transition-delay-translate: 0.3s;
    }

    &:nth-child(3) {
        --transition-delay-translate: 0.5s;
    }

    &:nth-child(4) {
        --transition-delay-translate: 0.7s;
    }

    &:nth-child(5) {
        --transition-delay-translate: 0.9s;
    }
}