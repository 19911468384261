@import '../../index.css';

.epic-font {
    text-shadow: 0 0 8px var(--text-color-1);
    background: -webkit-radial-gradient(circle,
                var(--text-color-1) 0%,
                var(--background-color-2) 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.knowledge-container {
    overflow: hidden;

    & section {
        align-items: center;
    }

    & h3 {
        transition: all 0s;
        margin-bottom: 0.5rem;
    }

    & li {
        list-style-type: none;
        align-items: center;
        margin-bottom: 0.2rem;
    }

    /* TODO BOUNCE ANIMATION */

    &[data-visible=true] {
        & * {
            transition-property: all;
            transition-duration: 0.4s;
            transition-timing-function: ease-in;
            transform: translateY(0px);
        }
    }

    &[data-visible=false] {
        & * {
            transition-property: all;
            transition-delay: 0s !important;
            transition-duration: 0s !important;
            transform: translateY(20px);
            opacity: 0;
        }
    }

    & :nth-child(1) {
        transition-delay: 0.1s;
    }

    & :nth-child(2) {
        transition-delay: 0.3s;
    }

    & :nth-child(3) {
        transition-delay: 0.5s;
    }

    & :nth-child(4) {
        transition-delay: 0.7s;
    }
}